@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_fonts';

// <a> tags are inline elements and obey line-height. When they contain only
// non-textual content (img, video, etc), the extra space provided by the
// line-height (defaults to 1.15, or ~+3px for typical font sizes) is often
// undesirable. This class eliminates that extra spacing by forcing the line
// to shrink to its smallest size while still containing its children.

.collapse-line {
  line-height: 0;
}

// Note that this just sets the line-height back to our site-wide default. It
// does *not* actually restore the line height to whatever was used before
// .collapse-line took effect. If you were using any line height other than
// $line-height-multiplier (1.15) prior to applying .collapse-line, then you
// must manually reset the line height to the desired value.
.uncollapse-line--standard {
  line-height: $line-height-multiplier;
}
