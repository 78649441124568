// ----------------------------------------------
// Imports
// ----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_animation';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/base/_action';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_fonts';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_z_index';

// ----------------------------------------------
// Variables
// ----------------------------------------------
$relaxed-queries-z-index: $base-z-index + 2;
$asset-checkmark-z-index: $overlay-z-index + 1;
$gallery-preview-z-index: $asset-checkmark-z-index + 1;
$action-icon-overlay-z-index: $gallery-preview-z-index + 1;
$gallery-batch-download-banner-z-index: $action-icon-overlay-z-index + 1;
$search-overlay-z-index: $action-icon-overlay-z-index + 1;
$filter-dropdown-z-index: $search-overlay-z-index;
$peeky-search-bar-z-index: $search-overlay-z-index + 1;
$filter-panel-overlay-z-index: $peeky-search-bar-z-index + 1;
$sticky-panel-z-index: $filter-panel-overlay-z-index;
$filter-panel-z-index: $filter-panel-overlay-z-index + 1;
$sticky-filter-dropdown-z-index: $filter-panel-z-index;
$search-filter-modal-z-index: $filter-panel-z-index + 1;
$gallery-overlay-z-index: $search-filter-modal-z-index + 1;
$calendar-border: $grey-40;

.headerSearchBar {
  background-color: $drk-light-grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 59px;
  z-index: 3;
  position: relative;

  button {
    border-radius: unset;
  }

  @media #{$medium-up} {
    padding: 1em 2em;
    height: 93px;
  }
}

// ----------------------------------------------
// Header and title
// ----------------------------------------------
.archival-calendar__content {
  max-width: 1440px;
  padding: 0 4rem;
  margin: {
    top: 2.5rem;
    right: auto;
    left: auto;
  }
}

.archival-calendar__page-header {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.archival-calendar__subheader {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 0.5rem;
}

// ----------------------------------------------
// Date Picker
// ----------------------------------------------
.archival-calendar-date-picker {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  font-size: 0.875rem;
  line-height: 0.875rem;

  &__button,
  &__button--submit,
  &__selector--year,
  &__selector--month {
    flex-basis: 100px;
    margin: {
      right: 12px;
      bottom: 10px;
    }
    padding: {
      top: 10px;
      bottom: 10px;
      left: 1rem;
    }

    cursor: pointer;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    padding-right: 1rem;
    border: 1px solid $gi-black;
    border-radius: 5px;

    &:hover {
      color: $gi-white;
      background-color: $gi-black;
    }

    &--next {
      margin-right: 1rem;
    }

    &--submit {
      padding-right: 1rem;
      font-size: 0.875rem;
    }
  }

  &__button-icon--next {
    margin-left: 0.5rem;
  }

  &__button-icon--previous {
    margin-right: 0.5rem;
  }

  &__selector--year,
  &__selector--month {
    padding-right: 2.2rem;
    color: $gi-black;
  }

  &__dropdown-container {
    @media #{$medium-up} {
      padding-left: 1rem;
      margin-left: 4px;
      border-left: 1px solid $grey-30;
    }
  }
}

// ----------------------------------------------
// Calendar
// ----------------------------------------------
.archival-calendar {
  min-width: 800px;

  margin: {
    top: 2rem;
    bottom: 5rem;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: 1px solid $calendar-border;
    font-size: 0.875rem;
  }

  &__day-header {
    display: flex;
    justify-content: center;
    padding: 1.2rem;
    color: $gi-black;
    text-transform: uppercase;

    &:not(:last-child) {
      border-right: 1px solid $calendar-border;
    }
  }

  &__body {
    display: grid;
    grid-template-rows: 150px 150px 150px 150px;
    grid-auto-rows: 150px;
    border-left: 1px solid $calendar-border;
  }

  &__week {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  &__day-container {
    position: relative;
  }

  &__day {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;

    border: {
      right: 1px solid $calendar-border;
      bottom: 1px solid $calendar-border;
    }
  }

  &__overlay-wrapper {
    overflow: hidden;
    height: 100%;
  }

  &__transform-wrapper {
    height: 100%;
    transition: transform $complex-duration ease;

    .archival-calendar__day-container:hover & {
      transform: scale(1.1);
      transition: all $complex-duration ease;
    }
  }

  &__day-overlay {
    position: absolute;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    background-color: rgba(8, 8, 8, 0.3);
  }

  &__day-overlay--opaque {
    background-color: rgba(255, 255, 255, 0.85);

    &:not(.archival-calendar__day--filler &) {
      .archival-calendar__day-container:hover & {
        background-color: rgba(8, 8, 8, 0.3);
      }
    }
  }

  &__date-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__date {
    font-size: 2.75rem;
    color: $gi-white;
    cursor: default;

    .archival-calendar__day--filler & {
      color: $grey-50;
    }

    &--opaque {
      color: $grey-50;

      .archival-calendar__day-container:hover & {
        color: $gi-white;
      }
    }
  }
}

// ----------------------------------------------
// Event List
// ----------------------------------------------
.event-list-container {
  display: none;
  position: absolute;
  top: 0;
  width: 380px;
  z-index: $search-filter-modal-z-index;
  background-color: $gi-white;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba($gi-white, 0) 0%,
      rgba($gi-white, 1) 100%
    );
    width: 100%;
    height: 1.5rem;
  }

  .archival-calendar__day-container:hover & {
    display: block;
  }

  &--right-panel {
    left: 100%;
  }

  &--left-panel {
    right: 100%;
  }

  &__header {
    margin: 0;
    padding: 0.625rem 1.25rem;
    background-color: $confirmed-color;
    color: $gi-white;
    font-size: 1rem;
    line-height: 1.1rem;
    text-transform: uppercase;
  }
}

.event-list__overflow-container {
  padding: {
    top: 0;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
  }
  max-height: 480px;
  overflow: scroll;
}

.event-list {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 1rem 0;
  padding-top: 1rem;

  &__image {
    max-width: 100%;
  }

  &__info {
    padding-left: 1rem;
  }

  &__link {
    display: block;
    padding-top: 1rem;

    color: $confirmed-color;
  }
}

.event-list__info,
.event-list__image-container {
  padding-bottom: 1rem;
  border-bottom: 1px solid $calendar-border;
}

.event-list__info:nth-last-child(1),
.event-list__image-container:nth-last-child(2) {
  padding-bottom: 0;
  border-bottom: 0;
}
