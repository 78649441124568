@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_animation';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';

// Search Bar Styles for 'Show Us' Page
.headerSearchBar {
  background-color: $drk-light-grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 59px;
  position: relative;
  z-index: 3;

  button {
    border-radius: unset;
  }

  @media #{$medium-up} {
    padding: 1em 2em;
    height: 93px;
  }
}

.show-us__hero {
  background-image: url('https://static.gettyimages.com/display-sets/dove/show_us_landing_hero_20190925-1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  align-items: center;
  color: $gi-white;
  display: flex;
  height: 390px;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media #{$medium-up} {
    background-position: top;
    height: 390px;
  }

  @media #{$large-up} {
    height: 435px;
  }

  @media #{$xlarge-up} {
    height: 620px;
  }
}

.show-us__img-overlay {
  background-color: $gi-black;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
}

.show-us-hero__heading {
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  padding: 0 30px;
  position: relative;
  text-align: center;

  @media #{$small-up} {
    font-size: 36px;
    line-height: 44px;
    max-width: 500px;
  }

  @media #{$medium-up} {
    font-size: 44px;
    line-height: 52px;
    max-width: 600px;
  }

  @media #{$large-up} {
    font-size: 48px;
    line-height: 57px;
    max-width: 700px;
  }
}

.show-us__content {
  margin: 40px auto;
  max-width: 1440px;
  padding: 0 32px;

  @media #{$xxlarge-up} {
    padding: 0;
  }
}

.show-us__intro {
  text-align: center;
}

.show-us-intro__title {
  font-size: 32px;
}

.show-us-intro__text {
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  padding: 20px 0;

  a {
    color: $primary-color;
  }

  @media #{$small-up} {
    width: 90%;
  }

  @media #{$large-up} {
    padding: 20px 32px;
  }
}

.show-us-intro__btn {
  display: inline-block;
  padding: 20px;
  margin-top: 30px;
}

.show-us-intro__logos {
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;

  @media #{$medium-up} {
    flex-direction: row;
  }
}

.show-us__logo {
  max-height: 30px;
  margin: 15px;
}

.show-us__logo--dove {
  max-height: 50px;
  padding: 0 60px;
}

.show-us__logo.show-us__logo--girlgaze {
  height: 34px;
  width: 142px;
}

// Browse section
.show-us-collection {
  margin: 100px 0;
}

.show-us-collection__heading {
  font-size: 24px;

  @media #{$small-up} {
    font-size: 28px;
  }
}

.show-us-collection__tiles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 28px;
}

.show-us-tile-container {
  display: block;
  overflow: hidden;
  width: 280px;
  position: relative;
  margin-top: 12px;
  height: 400px;
  flex-basis: 99%;

  @media #{$medium-up} {
    flex-basis: 49%;
  }

  @media #{$xlarge-up} {
    flex-basis: auto;
    width: 335px;
    flex-direction: row;
  }

  @media #{$xxlarge-up} {
    width: 350px;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .show-us-tile {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    color: $gi-white;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    transition: none;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }
}

.show-us-tile {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  color: $gi-white;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  transition: transform $complex-duration ease;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}

.show-us-collection-tiles__section {
  display: flex;
  flex-direction: column;

  @media #{$medium-up} {
    flex-direction: row;
  }

  @media #{$xlarge-up} {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.show-us-tile.show-us-tile--real {
  background-image: url('https://static.gettyimages.com/display-sets/dove/category_1.jpg');
}

.show-us-tile.show-us-tile--contentment {
  background-image: url('https://static.gettyimages.com/display-sets/dove/category_2.jpg');
}

.show-us-tile.show-us-tile--inspirational {
  background-image: url('https://static.gettyimages.com/display-sets/dove/category_3.jpg');
}

.show-us-tile.show-us-tile--beauty {
  background-image: url('https://static.gettyimages.com/display-sets/dove/category_4.jpg');
}

.show-us-tile__text {
  position: relative;
  font-size: 24px;

  @media #{$small-up} {
    font-size: 30px;
  }

  @media #{$large-up} {
    font-size: 32px;
  }
}
