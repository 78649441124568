@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';

.video-preview {
  // Note: this is intentionally not $gi-black. Some videos (especially
  // archival) already have black letterboxing or pillaring on them. We want
  // to match this as best as possible, which means using a neutral pure black
  // for our video backdrop.
  background-color: black;

  // This lets the .video-preview__view center vertically
  // with margin: auto
  justify-content: center;
  justify-items: center;

  &.is-loaded {
    .video-preview__poster {
      visibility: hidden;
    }
  }

  display: inline-block;
  overflow: hidden;
  height: max-content;
}

// Chrome 72 has a rendering bug that is triggered when certain
// layout-affecting properties are applied after others are applied.
// The number of properties that can affect this are undetermined but
// numerous; they seem to revolve around layout, specifically with grid
// and positioning. It manifests by the .video-preview__view tags being
// assigned 0 height & width. Toggling certain properties off/on in
// certain orders will trigger the bug.
//
// For example:
// 1. .video-preview starts with align-content: center and
//   align-items: center, and this triggers the bug (the videos and posters
//   are size zero).
// 2. Toggling align-items off doesn't fix the bug.
// 3. Toggling align-items off doesn't fix the bug.
// 4. Toggling align-items back on *does* fix the bug.
// 5. Toggling align-content back on causes the bug to reappear.
// 6. Toggling align-content back off doesn't fix the bug.
// 7. Toggling align-items back off *does* fix the the bug.
// 8. Toggling align-items back on doesn't cause the bug to reappear.
// 9. Toggling align-content back on *does* cause the bug to reappear.
//
// I've seen (but can't replicate) situations where toggling the
// top/bottom/left/right properties in .video-preview__video has similar
// effects.
//
// Toggling max-height: 100% on/off in .video-preview__view directly
// prevents/re-enables the bug, respectively.
//
// All of this is, at the time of this writing, only applicable to Chrome 72
// (latest stable). All other browsers and previous versions of Chrome don't
// exhibit any of this behaviour.
//
// As noted above, both align-content:center and align-items:center are
// required for Firefox and Safari compatibility. It's unknown whether future
// versions of Chrome will fix this issue. Thus, we'll use browser detection
// to limit this fix to Chrome.
//
// See further demonstrations here:
//   * Broken in Chrome 72: https://codepen.io/cwalker-getty/pen/RvZmeP
//   * Fix by removing align-content: https://codepen.io/cwalker-getty/pen/gqGOwm
//   * Fix by removing max-height: https://codepen.io/cwalker-getty/pen/MLEWjz

// Chrome-specific media query via http://browserhacks.com/
@media all and (min-device-pixel-ratio: 0) and (resolution >= 0.001dpcm) {
  .video-preview--chrome-72-fix {
    align-content: normal;
    align-items: normal;
  }
}

.video-preview__view {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

// Adding this modified element lets you stack the poster and video by the
// HTML order, thus avoiding the requirement for explicit z-indexes.
.video-preview__view--stacked {
  position: relative;
}

// Used to overlap the poster and video manually
.video-preview__video {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}

@media #{$ie11-media-feature} {
  .video-preview__view {
    margin-left: auto;
    margin-right: auto;
  }
}
