@use 'sass:math';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_animation';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';
@import 'getty/config/_z_index';

// As long as the grid is "full-bleed" (ie: there's no extra space between the images, and the image
// fills all of the space we give it), image width are determined by the amount of space available
// divided by the number of columns. We require that images are inherently square, so we do no height
// manipulation or calculation.
//
// Thus:
// * The 1st, with 1 column, has no defined start and ends at $mq-xsmall - 1 (639px).
//   * Min image size is technically 1px, but in practice is larger because of other limits in the page layout.
//     We almost never test on viewport sizes <320px; this is the effective min image size at this layout.
//   * Max image size is 639px
// * The 2nd layout, with 2 columns, starts at $mq-xsmall (640px) and ends at $large-media-breakpoint - 1 (1023px)
//   * Min image size 320px
//   * Max image size is 511.5px (round up to 512px)
// * The 3rd layout, with 4 columns, starts at $large-media-breakpoint (1024px)
//   and ends at $xxlarge-media-breakpoint - 1 (1839px)
//   * Min image size 256px
//   * Max image size is 459.75px (round up to 460px)
// * The 4th layout, with 5 columns, starts at $xxlarge-media-breakpoint (1840px) and has no defined end.
//   * Min image size 368px
//   * Since the inherent image size must be >=639px (the maximum max image size for all other layouts),
//     we can support viewport sizes of >=3,195px before we start up-scaling the images. We've decided that we
//     aren't overly concerned with viewport resolutions above 1840px.
//
// Thus, images must have a dimension of 639px x 639px, and are never displayed smaller than 256px x 256px.
//
// Note that the *first* image in the set is displayed in 2x2 grid columns at $large-media-breakpoint (1024px) and above.
// Thus it must have its dimensions as double the maximum max image size at the 3rd and 4th layouts.
// That works out to 736px x 736px (4th layout max x2).
// It is never displayed smaller than 320px. (This is the min image size for the 2nd layout. In the 3rd layout,
// where most images hit their minimum, the first image is displayed at double size (512px), which is larger
// than it's non-doubled size in the 2nd layout.)
//
// These calculations are true as of this writing. Check
// unisporkal_styles/app/assets/stylesheets/unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints.scss for the actual
// current values, and update these calculations accordingly.

$big-image-cell-size: 2 * 2;
// Note that this value must be kept in sync with CreativeHomeHelper::IMAGE_COUNT
// Also note that CreativeHomeHelper::FEWER_IMAGE_COUNT is irrelevant for the purposes
// of the stylesheet. Ultimately $grid-images is just used to calculate the row/column
// assignments for the IE11 grid, and those stay the same regardless of how many
// images are in it.
$grid-images: 57;

$fma-row: 4;
// IE 11 doesn't support `repeat`, but does have it's own syntax for the
// same functionality. See:
// https://css-tricks.com/css-grid-in-ie-debunking-common-ie-grid-misconceptions/
// Unfortunately, the syntax isn't valid CSS. We could fake SASS into
// outputting it via `unquote`, but the CSS compressor (used in the test
// and production Rails environments, but *not* in development) will still
// fail regardless. Instead, build the grid definitions manually using
// this helper function.
@function ie11GridRepeat($value, $repetitions) {
  $grid-def: $value;
  // SASS's @for/through does something weird if the end variable is bigger
  // than the start variable; it will iterate as if they were reversed.
  // So, we need to ensure that we don't iterate if we have < 2 repetitions
  @if $repetitions > 1 {
    @for $count from 2 through $repetitions {
      $grid-def: $grid-def $value;
    }
  }
  @return $grid-def;
}

@mixin grid-image-layout($columns, $big-first-image) {
  $cells: $grid-images;
  // We'll define the "regular grid" to be the grid excluding
  // any rows that contain a big image.
  // We'll deal with most of the "irregular grid" via
  // special cases.
  $regular-grid-first-row: 1;
  $regular-grid-first-image: 1;

  @if $big-first-image {
    $cells: $cells + $big-image-cell-size - 1;
    $regular-grid-first-row: 3;
    // With a "big" first image, we effectively reduce our column size
    // by one for the first 2 rows.
    $regular-grid-first-image: (2 * ($columns - 1));
  }
  $rows: floor(math.div($cells, $columns));

  .square-grid-container {
    // Grid columns have to be fully defined for IE11.
    // Grid rows can be implicit though.
    -ms-grid-columns: ie11GridRepeat(1fr, $columns);
    grid-template-columns: repeat($columns, 1fr);
  }

  @media #{$ie11-media-feature} {
    @if $columns > 1 {
      @for $column from 1 through $columns {
        $selector: '#{$columns}n+#{$regular-grid-first-image - 1 + $column}';
        .square-grid__item-container:nth-child(#{$selector}) {
          -ms-grid-column: $column;
        }
      }
    } @else {
      // If there's just the one column, we can skip all the work
      // with the nth-child selectors and just set the column once
      // for all cells
      .square-grid__item-container {
        -ms-grid-column: 1;
      }
    }

    @for $row from $regular-grid-first-row through $rows {
      $previous-regular-rows: $row - $regular-grid-first-row;
      $images-in-previous-regular-rows: $previous-regular-rows * $columns;
      $images-in-previous-rows: ($regular-grid-first-image - 1) +
        $images-in-previous-regular-rows;
      $first-image-in-row: $images-in-previous-rows + 1;
      $last-image-in-row: $first-image-in-row - 1 + $columns;
      $first-image-selector: 'n+#{$first-image-in-row}';
      $last-image-selector: '-n+#{$last-image-in-row}';

      // middle children range selector brought to you by http://nthmaster.com/
      .square-grid__item-container:nth-child(
          #{$first-image-selector}
        ):nth-child(#{$last-image-selector}) {
        @if $row >= $fma-row {
          $row: $row + 1;
        }
        -ms-grid-row: $row;
      }
    }
  }
}

.square-grid-container {
  -ms-grid-rows: 1fr;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row dense;
  position: relative;
  width: 100%;
}

@include grid-image-layout(1, false);

.square-grid__item-container {
  &:first-child {
    // Regardless of whether it's a regular or irregular grid,
    // the first image is always at 1,1.
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }
  &:hover {
    @media #{$large-up} {
      .square-grid-item__link--dim:after {
        opacity: 0.3;
      }
      .square-grid-item__img {
        transform: scale(1.1);
      }
      .square-grid-item__info {
        opacity: 1;
      }
    }
  }
}

@media #{$medium-up} {
  @include grid-image-layout(2, false);

  .square-grid__item-container:nth-last-child(2) {
    display: none;
  }
}

@media #{$large-up} {
  @include grid-image-layout(4, true);

  .square-grid__item-container {
    &:first-child {
      -ms-grid-column-span: 2;
      -ms-grid-row-span: 2;
      grid-column-end: span 2;
      grid-row-end: span 2;
    }
    &:nth-last-child(2) {
      display: block;
    }
  }

  @media #{$ie11-media-feature} {
    // The special cases need to be nested in order to properly override
    // the items defined in grid-image-layout
    .square-grid__item-container {
      &:nth-child(2),
      &:nth-child(4) {
        -ms-grid-column: 3;
      }
      &:nth-child(3),
      &:nth-child(5) {
        -ms-grid-column: 4;
      }

      &:nth-child(n + 2):nth-child(-n + 3) {
        -ms-grid-row: 1;
      }
      &:nth-child(n + 4):nth-child(-n + 5) {
        -ms-grid-row: 2;
      }
    }
  }
}

@media #{$xxlarge-up} {
  @include grid-image-layout(5, true);

  @media #{$ie11-media-feature} {
    .square-grid__item-container {
      &:nth-child(2),
      &:nth-child(5) {
        -ms-grid-column: 3;
      }
      &:nth-child(3),
      &:nth-child(6) {
        -ms-grid-column: 4;
      }
      &:nth-child(4),
      &:nth-child(7) {
        -ms-grid-column: 5;
      }

      &:nth-child(n + 2):nth-child(-n + 4) {
        -ms-grid-row: 1;
      }
      &:nth-child(n + 5):nth-child(-n + 7) {
        -ms-grid-row: 2;
      }
    }
  }
}

.square-grid-item__link {
  display: block;
  overflow: hidden;
  position: relative;

  // Fix specific for Firefox
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &:after {
    background-color: $gi-black;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.square-grid-item__link--dim {
  &:after {
    opacity: 0;
    transition: opacity $complex-duration ease;
  }
}

.square-grid-item__img {
  position: absolute;
  top: 0;
  left: 0;

  // The grid can't always divide the space into cells that have integer dimensions.
  // This means that the images end up being bordered by a tiny (but noticable
  // and annoying) white line on some browsers. Bumping the image size up by ~1px
  // removes this.
  height: 100.25%;
  width: 100.25%;
  margin: 0; // Can be removed when figure { margin: 0; } is added to Reset

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  transition: transform $complex-duration ease;
}

.square-grid-item__details {
  bottom: 0;
  line-height: 1.4;
}

.square-grid-item__item-id {
  font-size: 0.75em;
}

.square-grid-item__info {
  color: $gi-white;
  opacity: 1;
  padding: 32px;
  position: absolute;
  text-decoration: none;
  z-index: $square-grid-item-details-z-index;
  transition: opacity $complex-duration ease;

  @media #{$large-up} {
    opacity: 0;
    &:hover {
      text-decoration: underline;
    }
  }
}

.square-grid__fma-container {
  background-color: $gi-black;
  height: 330px;
  grid-row-start: $fma-row;
  grid-column-start: 1;
  grid-column-end: 2;
  position: relative;

  -ms-grid-row: $fma-row;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;

  @media #{$medium-up} {
    grid-column-end: 3;
    -ms-grid-column-span: 3;
  }

  @media #{$large-up} {
    grid-column-end: 5;
    -ms-grid-column-span: 5;
  }

  @media #{$xxlarge-up} {
    grid-column-end: 6;
    -ms-grid-column-span: 6;
  }
}

.square-grid-fma__details {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  padding-left: 1.875rem;
  position: relative;
  top: 70px;
  text-decoration: none;
  z-index: $square-grid-item-details-z-index;
  transition: opacity $complex-duration ease;

  @media #{$large-up} {
    top: 100px;
    padding-left: 4.75rem;
  }
  @media #{$mobile} {
    top: 35px;
  }
}

.square-grid-fma__font--black {
  color: $gi-black;
}

.square-grid-fma__font--white {
  color: $gi-white;
}

.square-grid-fma__details--full-width {
  padding-right: 1.875rem;

  @media #{$small-up} {
    margin-right: 240px;
  }
}

.square-grid-fma__title {
  text-decoration: none;
}

.square-grid-fma__subtitle {
  display: none;
  text-decoration: none;
  // Note: we don't actually have requirements for exactly how big the
  // subtitle should be or how much space it should take up. These values are
  // just placeholders for example purposes. Once an actual subtitled FMA
  // comes up, change these values accordingly.
  @media #{$small-up} {
    display: block;
    font-size: 20px;
    max-width: 520px;
  }
}

.square-grid-fma__text {
  padding-top: 8px;
  width: 70%;

  @media #{$mobile} {
    width: 100%;
  }

  &:hover {
    text-decoration: underline;
  }
}
.square-grid-fma__icon {
  color: $gi-white;
  height: 30px;
  width: 135px;
  position: absolute;
  right: 15px;
  bottom: 32px;

  @media #{$small-up} {
    height: 60px;
    width: 150px;
    right: 70px;
    bottom: 36px;
  }
}

.fma__arrow-icon {
  margin-left: 5px;
}

.square-grid-item__link--fma {
  height: inherit;
  color: inherit;

  display: block;
  overflow: hidden;
  position: relative;
}
