@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';

.creative-home-preview__banner {
  background-color: $alert-color;
  height: 100px;
  grid-column: 1 2;
  position: relative;
  color: $gi-white;
  text-align: center;
  padding-top: 1px;
}

.headerSearchBar {
  background-color: $drk-light-grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 59px;
  z-index: 3;

  button {
    border-radius: unset;
  }

  @media #{$medium-up} {
    padding: 1em 2em;
    height: 93px;
  }
}
