@use 'sass:math';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_z_index';

.partner-landing-page__text {
  font-size: 1.125rem;
  line-height: 1.6em;
}

.partner-landing-page__text--larger {
  line-height: 1.4em;
  font-size: 1rem;

  @media #{$medium-up} {
    font-size: 1.3125rem;
  }
}

.partner-landing-page-search-bar__main-area {
  align-items: center;
  display: flex;
  flex: 3 0 auto;
}

.partner-landing-page-hero {
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($gi-black, 0.3);
  }
}

.partner-landing-page-hero-overlay__title {
  margin-bottom: 2rem;
  width: 100%;

  @media #{$medium-up} {
    width: 50%;
  }

  @media #{$large-up} {
    width: 40%;
  }
}

.partner-landing-page-hero__overlay {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 50%;
  left: 50%;
  max-width: 1440px;
  transform: translate(-50%, -50%);
}

.partner-landing-page-bar-bar__form {
  display: flex;
  flex-direction: row-reverse;
  height: 3.75em;
  background-color: $gi-white;
  border: {
    top: 1px solid $gi-black;
    bottom: 1px solid $gi-black;
  }

  width: 100%;

  @media #{$medium-up} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $gi-black;
  }

  @media #{$medium-up} {
    border: 1px solid $gi-black;
  }
}

.partner-landing-page-bar-form__input {
  border: none;
  outline-width: 0;
  width: 100%;
  height: 100%;
  padding: 1em 1em 1em 0.5em;
}

.partner-landing-page-bar-form__magnifying-glass {
  display: flex;
  align-items: center;
  color: $gi-black;
  height: 100%;
  padding-right: 1em;
  padding-left: 1em;
}

.partner-landing-page-bar-bar__container {
  position: relative;
  width: 100%;

  @media #{$medium-up} {
    width: 80%;
  }
}

.partner-landing-page-display-set__videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;

  @media #{$medium-up} {
    margin-top: 32px;
  }
}

.partner-landing-page__display-set {
  margin-top: 4em;
  display: flex;
  justify-content: center;

  @media #{$ie11-media-feature} {
    display: block;
  }
}

.partner-landing-page-display-set__asset {
  flex-basis: 100%;
  max-width: 100%;
  margin-bottom: 1em;

  @media #{$medium-up} {
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding-right: 1em;
    padding-bottom: 1em;
  }

  @media #{$xlarge-up} {
    flex-basis: math.div(1, 3) * 100%;
    max-width: math.div(1, 3) * 100%;
  }
}

.partner-landing-page-display-set-asset__sizer {
  // These values were determined experimentally
  min-height: 144px;

  @media #{$medium-up} {
    min-height: 190px;
  }

  a {
    height: 100%;
  }
}

.partner-landing-page-display-set-asset__link {
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($gi-black, 0.3);
    z-index: $bbc-display-asset-z-index;
  }
}

.partner-landing-page-display-set-asset__overlay {
  align-items: center;
  color: $gi-white;
  display: flex;
  flex-direction: row;
  font-size: 2rem;
  justify-content: center;
  left: 50%;
  padding: 1em;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: $bbc-display-asset-z-index;
}

.partner-landing-page-display-set-asset__content {
  object-fit: cover;
  min-height: 100%;
  min-width: 100%;

  @media #{$ie11-media-feature} {
    min-height: auto;
    min-width: auto;
  }
}

.partner-landing-page-explore__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4em 2em 0;
  margin: auto;
  max-width: 1440px;
  text-align: center;
}

.partner-landing-page-explore__info-text {
  margin-top: 0;
  margin-bottom: 1.5em;
  width: 100%;

  @media #{$medium-up} {
    width: 90%;
  }
}

.partner-landing-page-explore__link {
  display: block;
  padding: 1em;
  white-space: nowrap;

  @media #{$medium-up} {
    padding: 1em 2em;
  }
}

.partner-landing-page-explore__button-text {
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

.partner-landing-page-video-collection {
  flex-grow: 1;
  margin: 0 2em;
  max-width: $xlarge-media-breakpoint;

  @media #{$ie11-media-feature} {
    @media #{xlarge-up} {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.partner-landing-page-contact__container {
  @extend .partner-landing-page-explore__container;

  padding: 2em 2em 4em;
}

.partner-landing-page-contact__info-text {
  margin-top: 0;
  margin-bottom: 1.5em;
  width: 100%;

  @media #{$medium-up} {
    width: 90%;
  }
}

.partner-landing-page-text-links--tiny {
  line-height: 1.75em;
  width: 90%;

  @media #{$medium-up} {
    width: 100%;
  }
}

.partner-landing-page-display-set__title {
  font-size: 1.35rem;

  @media #{$medium-up} {
    font-size: 1.75em;
  }
}

.partner-landing-page__heading--no-spacing {
  margin: 0;
}

.partner-landing-page__heading--reset-font {
  font-size: inherit;
  font-weight: inherit;
}

// ITN specific adjustments to shared styles due to differences in content

.itn-landing-page-hero-overlay__title {
  @extend .partner-landing-page-hero-overlay__title;

  max-width: 142px;

  @media #{$medium-up} {
    max-width: 163px;
  }

  @media #{$large-up} {
    max-width: 183px;
  }
}

.itn-landing-page-hero__overlay {
  @extend .partner-landing-page-hero__overlay;

  top: 45%;
}
