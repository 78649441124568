@use 'sass:math';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';

.video-home-page {
  margin-bottom: 51px;
}

.video-home-page-hero__overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  left: 0;

  @media #{$small-up} {
    flex-direction: column;
  }
  @media #{$medium-up} {
    height: auto;
  }
}

.video-home-page-hero-overlay__title {
  font-size: 24px;
  text-align: center;
  width: 100%;

  @media #{$small-up} {
    margin-bottom: 50px;
  }

  @media #{$medium-up} {
    font-size: 28px;
    margin-bottom: 20px;
  }

  @media #{$large-up} {
    font-size: 32px;
  }

  @media #{$xlarge-up} {
    font-size: 45px;
    line-height: 54px;
    max-width: 690px;
    margin-bottom: 48px;
  }
}

.video-home-page-hero-overlay__benefits {
  display: none;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 53px;

  @media #{$small-up} {
    display: flex;
  }

  @media #{$medium-up} {
    flex-direction: row;
    margin-bottom: 20px;
  }

  @media #{$large-up} {
    margin-bottom: 34px;
  }

  @media #{$xlarge-up} {
    margin-bottom: 42px;
  }
}

.video-home-page-hero-overlay-benefit {
  $border-radius: 4px;
  align-items: center;
  display: flex;
  border-radius: $border-radius;
  border: 1px solid $gi-white;
  margin-bottom: 10px;
  padding: 14px 17px;

  @media #{$medium-up} {
    max-width: 242px;

    // Regardless of how many benefits are present,
    // all but the first have square left corners,
    // and all but the last have square right corners.
    // All but the first are missing their left border entirely.
    &:nth-child(1n + 2) {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:nth-last-child(1n + 2) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.video-home-page-hero-overlay-benefit__checkmark {
  flex-shrink: 0;
  height: 12px;
  margin-right: 9px;
  width: 18px;
}

.video-home-page-hero-overlay-benefit__text {
  font-size: 14px;
}

$horizontal-spacing-small: 20px;
// This is the maximum content spacing possible to support
// 3 videos w/o clipping and all other spacing according to
// the mockup, while keeping the content centred.
// Note that the mockup doesn't centre the content.
$horizontal-spacing-medium: 31px;
$horizontal-spacing-xlarge: 78px;

// $xlarge-plus is enough space for the content to take up 1440px
// plus the xlarge horizontal margins. It exists to limit
// the content size to 1440px, not including the margins.
$xlarge-plus-media-breakpoint: $xlarge-media-breakpoint +
  ($horizontal-spacing-xlarge * 2);
$xlarge-plus-up: media-range($xlarge-plus-media-breakpoint);

.video-home-page-content {
  margin-left: $horizontal-spacing-small;
  margin-right: $horizontal-spacing-small;
}

.video-home-page-content--small-full-width {
  margin-left: 0;
  margin-right: 0;
}

.video-home-page-content--medium-spacing {
  @media #{$medium-up} {
    margin-left: $horizontal-spacing-medium;
    margin-right: $horizontal-spacing-medium;
  }

  @media #{$xlarge-up} {
    margin-left: $horizontal-spacing-xlarge;
    margin-right: $horizontal-spacing-xlarge;
  }

  @media #{$xlarge-plus-up} {
    margin-left: auto;
    margin-right: auto;
    max-width: $xlarge-media-breakpoint;
  }
}

.video-home-page__display-sets {
  margin-top: 42px;

  @media #{$medium-up} {
    margin-top: 59px;
  }

  @media #{$xlarge-up} {
    margin-top: 73px;
  }
}

.video-home-page-display-set {
  &:nth-child(n + 2) {
    border-top: 1px solid $grey-30;
    margin-top: 30px;
    padding-top: 32px;

    @media #{$medium-up} {
      margin-top: 44px;
      padding-top: 46px;
    }
  }
}

.video-home-page-display-set__header {
  display: flex;
  align-items: center;
}

.video-home-page-display-set__title {
  font-size: 16px;
  flex-grow: 1;
  margin: 0;

  @media #{$medium-up} {
    font-size: 28px;
  }
}

.video-home-page-display-set__link {
  font-size: 13px;
  padding: 6px 13px;
  margin-left: 4px;
  white-space: nowrap;

  @media #{$medium-up} {
    padding: 8px 20px;
  }
}

.video-home-page-display-set__videos {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  @media #{$medium-up} {
    margin-top: 32px;
  }
}

// This is the aspect ratio of the space we allocate for videos.
// Ideally, this is also the aspect ratio of the video itself, which means it
// completely covers the allocated space. However, videos often are different
// aspect ratios (4:3 is common for archival; 4k is often 1.9 or 1.85 vs 1.78).
// Videos that don't fit 16:9 will have black borders (either pillars or
// letterboxing) around them.
$aspect-ratio: math.div(16, 9);
// These define(d) the base video widths for different breakpoints. Note though
//  that we subsequently made the video widths responsive so that they would
// fill the available width.
$xsmall-video-width: 200px;
$medium-video-width: 305px;
$medium-vertical-width: 97px;
$medium-vertical-height: 172px;
$horizontal-video-width: 460px;
$vertical-video-width: 145px;
$vertical-video-height: 260px;
// However, to make the videos appear correctly (specifically on
// iOS 11 on iPads) we needed to specify a minimum height for
// .video-home-page-display-set-asset__sizer (which in turn defines the height
// for the video/poster). We can calculate that easily using the aspect ratio.
$xsmall-video-height: math.div($xsmall-video-width, $aspect-ratio);
$medium-video-height: math.div($medium-video-width, $aspect-ratio);

.video-home-page-display-set__asset {
  flex-shrink: 0;
  flex-grow: 1;

  &:nth-child(1n + 2) {
    margin-left: 1em;
  }
}

.vertical {
  flex-grow: 1;
  width: $medium-vertical-width;
  height: $medium-vertical-height;

  &:nth-last-child(-n + 2) {
    display: none;
  }

  @media screen and (min-width: $large-media-breakpoint) {
    width: $vertical-video-width;
    height: $vertical-video-height;
    &:nth-last-child(-n + 2) {
      display: block;
    }
  }

  @media #{$large-up} {
    flex-shrink: 1;
  }
}

.horizontal {
  flex-grow: 3;
  width: $medium-video-width;

  @media screen and (min-width: $large-media-breakpoint) {
    width: $horizontal-video-width;
  }
  @media #{$large-up} {
    flex-shrink: 1;
  }
}

.video-home-page-display-set-asset__sizer {
  min-height: $xsmall-video-height;

  @media #{$medium-up} {
    min-height: $medium-video-height;
  }
}

.video-home-page-display-url-warning {
  line-height: 1;
  color: red;
  position: absolute;
  top: 0;
}

@media #{$ie11-media-feature} {
  .video-home-page-display-set-asset__content {
    // IE11 doesn't support max-height: 100% either.
    // This height cap was determined experimentally.
    max-height: 150px;
  }
}

@supports ($edge-supports-property: $edge-supports-value) {
  .video-home-page-display-set-asset__content {
    // Edge doesn't support max-width: 100%, which is critical for
    // full-fledged scaling. Instead, cap the height to prevent it from escaping
    // the containing box. Edge users will get extra letterboxing /
    // pillaring; that's the price they pay for using a problematic browser.
    // This height cap was determined experimentally.
    max-height: 170px;
  }
}

.video-home-page-display-set-asset__overlay {
  bottom: 0;
  color: $gi-white;
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
  padding: 1em;
  left: 0;
  position: absolute;
  width: 100%;
}

.video-home-page-display-set-asset__overlay-item {
  background-color: rgba($gi-black, 0.6);
  border-radius: 4px;
  margin-left: 1em;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.video-home-page-display-set-asset-sub-item-count__icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}

.video-home-page-display-set-asset__title {
  font-size: 14px;
  margin-top: 14px;

  @media #{$medium-up} {
    font-size: 16px;
  }
}

.video-home-page__featured-partners {
  margin-top: 60px;

  @media #{$large-up} {
    margin-top: 80px;
  }
}

.video-home-page-featured-partners__title {
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;

  @media #{$xlarge-up} {
    font-size: 28px;
  }
}

.video-home-page-featured-partners__content {
  margin-top: 30px;

  @media #{$xlarge-up} {
    display: flex;
  }
}

.video-home-page-bbc-motion-gallery {
  align-items: center;
  background-image: url(~static_content/home/featured_partners/bbc_motion_gallery.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: $gi-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  min-height: 404px;
  padding: 74px 20px;
  text-align: center;

  @media #{$medium-up} {
    justify-content: center;
    min-height: 527px;
  }

  @media #{$xlarge-up} {
    flex: 0 0 auto;
    width: 740px;
  }
}

.video-home-page-bbc-motion-gallery__title {
  height: 26px;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;

  @media #{$medium-up} {
    height: 34px;
  }

  @media #{$large-up} {
    height: 38px;
  }
}

.video-home-page-bbc-motion-gallery__instruction {
  font-size: 18px;
  line-height: 23px;
  margin-top: 28px;

  @media #{$medium-up} {
    font-size: 21px;
    line-height: 25px;
    max-width: 517px;
  }
}

.video-home-page-bbc-motion-gallery__cta {
  background-color: $gi-white;
  color: $gi-black;
  margin-top: 28px;
  padding: 14px 27px;
  max-width: 227px;

  @media #{$medium-up} {
    max-width: none;
  }

  @media #{$large-up} {
    border: 1px solid $gi-white;
    background: none;
    color: $gi-white;
    margin-top: 53px;
    padding: 20px 52px;

    &:active,
    &:focus,
    &:hover:not([disabled]) {
      background-color: $gi-white;
      color: $gi-black;
    }
  }
}

.video-home-page-major-partners {
  width: 100%;
  $border: 1px solid $grey-30;
  padding: 32px 29px;
  background-color: $grey-20;
  border-bottom: $border;

  @media #{$medium-up} {
    border-left: $border;
    border-right: $border;
  }

  @media #{$xlarge-up} {
    border-left: none;
    border-top: $border;
    padding: 24px 29px;
  }
}

.video-home-page-major-partner {
  @media #{$medium-up} {
    display: flex;
  }

  &:nth-child(n + 2) {
    margin-top: 24px;
    border-top: 1px solid $grey-30;
    padding-top: 24px;
  }
}

.video-home-page-major-partner__image {
  height: 185px;
  width: 264px;

  @media #{$medium-up} {
    flex-shrink: 0;
    height: 140px;
    width: 200px;
  }
}

.video-home-page-major-partner__text {
  margin-top: 16px;

  @media #{$medium-up} {
    margin-top: 0;
    margin-left: 20px;
  }
}

.video-home-page-major-partner__nbc-news-logo {
  height: 15px;
  width: 160px;
}

.video-home-page-major-partner__bloomberg-logo {
  height: 24px;
  width: 106px;
}

.video-home-page-major-partner__sony-logo {
  height: 28px;
  width: 96px;
}
.video-home-page-major-partner__sony-logo-full {
  height: 100px;
  width: 100px;
}

.video-home-page-major-partner__universal-logo {
  height: 56px;
  width: 101px;
}

.video-home-page-major-partner__smithsonian-logo {
  height: 28px;
  width: 138px;
}

.video-home-page-major-partner__lighthouse-logo {
  height: 30px;
  width: 190px;
}

.video-home-page-major-partner__itn-logo {
  height: 52px;
  width: 104px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.video-home-page-major-partner__description {
  margin-top: 24px;

  @media #{$medium-up} {
    margin-top: 20px;
  }
}

.video-home-page-major-partner__destination {
  display: inline-block;
  margin-top: 28px;
}

.video-home-page-major-partner__arrow-icon {
  margin-left: 5px;
}

.video-home-page__partners {
  margin-top: 46px;

  @media #{$medium-up} {
    margin-top: 61px;
  }

  @media #{$xlarge-up} {
    margin-top: 50px;
  }
}

.video-home-page-partners__title {
  font-size: 24px;
  margin-bottom: 0;
}

.video-home-page-partners__list {
  margin-top: 39px;

  @media #{$medium-up} {
    columns: 2;
  }

  @media #{$medium-up} {
    columns: 3;
  }

  @media #{$xlarge-up} {
    columns: 4;
  }
}

.video-home-page__partner {
  &:nth-child(n + 2) {
    margin-top: 11px;
  }
}

@supports ($edge-supports-property: $edge-supports-value) {
  .video-home-page-display-set-asset__content {
    // Edge doesn't support max-width: 100%, which is critical for
    // full-fledged scaling. Instead, cap the height to prevent it from escaping
    // the containing box. Edge users will get extra letterboxing /
    // pillaring; that's the price they pay for using a problematic browser.
    // This height cap was determined experimentally.
    max-height: 170px;
  }
}
