@use 'sass:math';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';

// NOTE: What we want is for the video to automatically scale to fit the
// size of .hero-video. Videos are currently expected to be inherently
// 1920x1080.
// On mobile/small widths, which are taller than it is wide, scale the
// video to fit the height, and then crop left & right.
// On everything else which is wider than it is tall, we want to scale
// to fit the width, and then crop the top and bottom to fit the height
// constraint.
//
// In everything but IE11 and Edge, object-fit: cover works perfectly; we
// just need to specify our height constraint.
//
// IE11 and Edge (as of v18, for video-only) do not support
// object-fit: cover. Instead we have to fake it manually. These become
// the "default" properties for .video-home-page-hero and
// .video-home-page-hero__video.
//
// IE11 doesn't support @supports, and so it ignores everything in the
// @supports sections. This allows us to target IE11 in CSS without issue;
// see details in comments below.
//
// Edge supports @supports, but fails on @supports(object-fit:cover), so it
// will also ignore the overrides.
//
// All other browsers that we support also support @supports. They see the
// @supports test, pass it, and so apply the styling we really want. That
// styling must override all the hacks we put in for IE11.
.hero-video {
  align-items: center;
  background-color: $gi-black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.hero-video--standard-height {
  height: 348px;

  @media #{$medium-up} {
    height: 330px;
  }

  @media #{$large-up} {
    height: 440px;
  }

  @media #{$xlarge-up} {
    height: 558px;
  }
}

// Note that Edge supports object-fit: cover, but *only* on img elements,
// not video. That means that the @supports test passes, but doesn't give
// us the behaviour we want. So, we need to disable this enhancement for
// Edge and provide the alternate implementation below.
@supports (object-fit: cover) and
  (not ($edge-supports-property: $edge-supports-value)) {
  .hero-video__video {
    object-fit: cover;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    @media #{$medium-up} {
      width: 100%;
    }
  }
}

// IE11 doesn't support @supports, and Edge doesn't have
// a reliable media query that can use to target it, so we have
// no mechanism to target both IE11 and Edge simultaneously.
// Instead, create a mixin that we can then apply to the individual
// IE11- and Edge-specific targeting methods.
// All of these styles are to work around the lack of object-fit: cover
// support in IE11 and Edge.
@mixin ieVideoSize($next-largest-media-breakpoint) {
  $width: $next-largest-media-breakpoint;

  height: ceil(math.div($width, 16) * 9);
  width: $width;
}

@mixin hero-video__video-without-object-fit-cover {
  .hero-video__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hero-video__video--hd {
    @include ieVideoSize($medium-media-breakpoint);

    @media #{$medium-up} {
      @include ieVideoSize($large-media-breakpoint);
    }

    @media #{$large-up} {
      @include ieVideoSize($xlarge-media-breakpoint);
    }

    // IE won't scale the video beyond it's native resolution,
    // so this is the end of the line as far as breakpoints go.
    @media #{$xlarge-up} {
      height: 1080px;
      width: 1920px;
    }
  }
}

@media #{$ie11-media-feature} {
  @include hero-video__video-without-object-fit-cover;
}

@supports ($edge-supports-property: $edge-supports-value) {
  @include hero-video__video-without-object-fit-cover;
}

.hero-video__overlay {
  color: $gi-white;
  position: absolute;
  width: 100%;
  padding: 0 36px;
}
